import fb from "gatsby-plugin-firebase-v9.0"
import { getFirestore, collection, getDocs } from "firebase/firestore/lite"
import { getStorage } from "firebase/storage"

const fs = getFirestore(fb)
const storage = getStorage()

const getCollection = async colString => {
  const col = await collection(fs, colString)
  const snapShot = await getDocs(col)
  return snapShot.docs.map(doc => doc.data())
}

export { getCollection, fs, storage }
