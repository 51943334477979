exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-add-client-index-js": () => import("./../../../src/pages/admin/AddClient/index.js" /* webpackChunkName: "component---src-pages-admin-add-client-index-js" */),
  "component---src-pages-admin-add-lecture-index-js": () => import("./../../../src/pages/admin/AddLecture/index.js" /* webpackChunkName: "component---src-pages-admin-add-lecture-index-js" */),
  "component---src-pages-admin-add-location-index-js": () => import("./../../../src/pages/admin/AddLocation/index.js" /* webpackChunkName: "component---src-pages-admin-add-location-index-js" */),
  "component---src-pages-admin-add-recommendation-index-js": () => import("./../../../src/pages/admin/AddRecommendation/index.js" /* webpackChunkName: "component---src-pages-admin-add-recommendation-index-js" */),
  "component---src-pages-admin-add-serie-index-js": () => import("./../../../src/pages/admin/AddSerie/index.js" /* webpackChunkName: "component---src-pages-admin-add-serie-index-js" */),
  "component---src-pages-admin-add-tour-index-js": () => import("./../../../src/pages/admin/AddTour/index.js" /* webpackChunkName: "component---src-pages-admin-add-tour-index-js" */),
  "component---src-pages-admin-edit-lecture-index-js": () => import("./../../../src/pages/admin/EditLecture/index.js" /* webpackChunkName: "component---src-pages-admin-edit-lecture-index-js" */),
  "component---src-pages-admin-edit-location-index-js": () => import("./../../../src/pages/admin/EditLocation/index.js" /* webpackChunkName: "component---src-pages-admin-edit-location-index-js" */),
  "component---src-pages-admin-edit-serie-index-js": () => import("./../../../src/pages/admin/EditSerie/index.js" /* webpackChunkName: "component---src-pages-admin-edit-serie-index-js" */),
  "component---src-pages-admin-edit-tour-index-js": () => import("./../../../src/pages/admin/EditTour/index.js" /* webpackChunkName: "component---src-pages-admin-edit-tour-index-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lecture-js": () => import("./../../../src/pages/lecture.js" /* webpackChunkName: "component---src-pages-lecture-js" */),
  "component---src-pages-lectures-js": () => import("./../../../src/pages/lectures.js" /* webpackChunkName: "component---src-pages-lectures-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-serie-js": () => import("./../../../src/pages/serie.js" /* webpackChunkName: "component---src-pages-serie-js" */),
  "component---src-pages-tour-js": () => import("./../../../src/pages/tour.js" /* webpackChunkName: "component---src-pages-tour-js" */),
  "component---src-pages-upcoming-js": () => import("./../../../src/pages/upcoming.js" /* webpackChunkName: "component---src-pages-upcoming-js" */)
}

