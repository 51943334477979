import { getCollection } from "./src/utils/firebase"

export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    const now = new Date()
    // now.setHours(now.getHours() + 3)
    getCollection("tours").then(d =>
      window?.localStorage.setItem(
        "tours",
        JSON.stringify(
          d
            .map(t => ({
              ...t,
              dates: t.dates.filter(
                date => new Date(date?.time).getTime() > now.getTime()
              ),
              imgs: t.imgs?.sort((a, b) => a?.priority - b?.priority),
            }))
            .sort(
              (a, b) =>
                new Date(a.dates[0]?.time).getTime() -
                new Date(b.dates[0]?.time).getTime()
            )
        )
      )
    )
    getCollection("series").then(d =>
      window?.localStorage.setItem("series", JSON.stringify(d))
    )
    getCollection("locations").then(d =>
      window?.localStorage.setItem("locations", JSON.stringify(d))
    )
    getCollection("recommendations").then(d =>
      window?.localStorage.setItem("recommendations", JSON.stringify(d))
    )
    getCollection("clients").then(d =>
      window?.localStorage.setItem("clients", JSON.stringify(d))
    )
    getCollection("lectures").then(d =>
      window?.localStorage.setItem(
        "lectures",
        JSON.stringify(
          d.map(l => ({
            ...l,
            dates: l.dates.filter(
              date => new Date(date?.time).getTime() > now.getTime()
            ),
            imgs: l.imgs?.sort((a, b) => a?.priority - b?.priority),
          }))
          .sort(
            (a, b) =>
              new Date(a.dates[0]?.time).getTime() -
              new Date(b.dates[0]?.time).getTime()
          )
        )
      )
    )
  }
}
